import { apiSlice } from "../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: "user",
        method: "GET",
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "user/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    activationDesactivationUser: builder.mutation({
      query: (body) => ({
        url: "user/change_state",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `user/update/${body.id_user}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (body) => ({
        url: `user/del/${body.id_user }`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useActivationDesactivationUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
