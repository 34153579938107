import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "../../helpers";
import { jwtDecode } from 'jwt-decode';

const userSlice = createSlice({
  name: "user",
  initialState: {
    info: localStorage.getItem("mon_user")
      ? JSON.parse(localStorage.getItem("mon_user"))
      : {},
  },
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("mon_user", JSON.stringify(action.payload));
      state.info = action.payload;
    },
    logOut: (state, action) => {
      state.info = {};
      localStorage.removeItem("mon_user");
      deleteCookie("userInfo");
    },

  },
});

export const { setUser, logOut } = userSlice.actions;

export const getUserInfo = (state) => state.user.info;

export const isTokenExpired = (state) => {
  const token = state.user.info?.token;
  if (!token) return true; // Pas de jeton
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

 export  const verifierAdminAcces = (state) => {
  const profil = state.user.info?.profil;
 if (!profil || typeof profil !== 'string') {
  return 'non';
} 
const parties = profil.split('_');
if (parties[0] === 'Super') {
  return 'Super_Administrateur';
}else if(parties[0] === 'Administrateur'){
  return 'Administrateur';
}else{
  return 'non';
}
};


export default userSlice.reducer;
