import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer.js";
import Header from "../../layouts/Header.js";
import moment from "moment";
import TableLog from "./dataTable/TableLog.js";
import Sidebar from "../../layouts/Sidebar.js";

import {
  useGetLogUserPageQuery,
  useCreateLogPageMutation,
  useGetLogEntre2PeriodesQuery,
} from "../../redux/apiSlice/logPageApiSlice.js";
import { getUserInfo, isTokenExpired, logOut } from '../../redux/slice/userSlice';
import { useSelector, useDispatch } from "react-redux";

export default function ListePlainte() {
  const [pageVisite, setPageVisite] = useState([]);
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  const { data: listePageVisite } = useGetLogUserPageQuery();

  const [timestamps, setTimestamps] = useState({
    date_debut: "",
    date_fin: "",
  });
  const { data: pageVisiteeEntre2Periodes } = useGetLogEntre2PeriodesQuery({
    debut: new Date(timestamps.date_debut).getTime(),
    fin: new Date(timestamps.date_fin).getTime(),
  });

  const [errorMessage, setErrorMessage] = useState({
    msgDebut: "",
    msgFin: "",
  });

  const logsEntre2Dates = (champ, value) => {
    setTimestamps({
      ...timestamps,
      [champ]: value,
    });
  };

  useEffect(() => {
    if (timestamps.date_debut && timestamps.date_fin) {
      if (moment(timestamps.date_debut).isBefore(moment(timestamps.date_fin))) {
        const filtrePageVisite = pageVisiteeEntre2Periodes?.map((page) => ({
          nom: user?.name,
          page: page?.content,
          date_log: moment(parseInt(page?.date_log)).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
        }));

        setPageVisite(filtrePageVisite);
        setErrorMessage({
          msgDebut: "",
          msgFin: "",
        });
      } else {
        setPageVisite([]);
        setErrorMessage({
          msgDebut:
            timestamps.date_debut &&
            moment(timestamps.date_debut).isAfter(moment(timestamps.date_fin))
              ? "Date de début est postérieur à la date de fin."
              : "",
          msgFin:
            timestamps.date_fin &&
            moment(timestamps.date_fin).isBefore(moment(timestamps.date_debut))
              ? "Date de fin est antérieur à la date de début."
              : "",
        });
      }
    } else {
      setErrorMessage({
        msgDebut: "",
        msgFin: "",
      });
    }
  }, [timestamps.date_debut, timestamps.date_fin]);

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({ content: "Liste logs" }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  const user = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();
  
      useEffect(() => {
      if (isExpired) {
      dispash(logOut())
      window.location.href = "/";
      }  
      }, [isExpired]);

  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }

    const newPageVisite = listePageVisite?.map((page) => ({
      nom: user?.name,
      page: page?.content,
      date_log: moment(parseInt(page?.date_log)).format("DD/MM/YYYY HH:mm:ss"),
    }));

    setPageVisite(newPageVisite);
  }, [user, listePageVisite?.length]);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Log</Link>
              </li>
            </ol>
            <h4 className="main-title mb-0">Pages visitées</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row>
              <Col sm="12" xl="12">
                <h5>Liste des logs</h5>
              </Col>
              <Col sm="12" xl="6" className="d-flex flex-column mb-4">
                <Form.Label htmlFor="dateDebut">Date de début *:</Form.Label>

                <Form.Control
                  id="dateDebut"
                  type="date"
                  placeholder="Date de début"
                  value={timestamps.date_debut}
                  onChange={(e) =>
                    logsEntre2Dates("date_debut", e.target.value)
                  }
                />
                {errorMessage.msgDebut && (
                  <span className="text-danger">{errorMessage.msgDebut}</span>
                )}
              </Col>

              <Col sm="12" xl="6" className="d-flex flex-column mb-4">
                <Form.Label htmlFor="dateDebut">Date de fin *:</Form.Label>

                <Form.Control
                  id="dateDebut"
                  type="date"
                  placeholder="Date de fin"
                  value={timestamps.date_fin}
                  onChange={(e) => logsEntre2Dates("date_fin", e.target.value)}
                />
                {errorMessage.msgFin && (
                  <span className="text-danger">{errorMessage.msgFin}</span>
                )}
              </Col>
            </Row>
            <Row className="g-2">
              <Col sm="12" xl="12">
                <TableLog log={pageVisite} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
