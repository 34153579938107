import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import { Grid } from "gridjs-react";
import TableSolution from "./dataTables/TableSolution";
import Spinner from "react-bootstrap/Spinner";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";

import { useSelector, useDispatch  } from "react-redux";

import {
    useCreateSolutionMutation,
    useUpdateSolutionMutation,
    useDeleteSolutionMutation,
    useGetSolutionQuery,
    useGetSolutionAllQuery,
    useGetSolutionOtherQuery
} from "../../redux/apiSlice/plainteApiSlice";
import Swal from "sweetalert2";
import { getUserInfo, isTokenExpired, logOut } from '../../redux/slice/userSlice';

export default function Solution() {

    // modal 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const init = {
        lib_solution: '',
    }

    const [form, setForm] = useState({ ...init })
    const [catSup, setCatSup] = useState()

    ///// Skin Switch /////
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    const { data: solution = [] } = useGetSolutionQuery()
    const { data: solutionAll = [] } = useGetSolutionAllQuery()
    const { data: solutionOther = [] } = useGetSolutionOtherQuery()

    const [createData, { isLoading: loadCreate }] = useCreateSolutionMutation()
    const [updateData] = useUpdateSolutionMutation()
    const [deleteData] = useDeleteSolutionMutation()
    const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
    

   
  
    const changeForm = (field, value) => setForm({ ...form, [field]: value })

    const save = async () => {

        try {

            if (form?.id_solution) {

                await updateData({...form, id : form?.id_solution}).unwrap()
                setForm({ ...init })
                Swal.fire({
                    title: "SUCCES!",
                    text: "Action réussie",
                    icon: "success"
                });
            } else {
                await createData(form).unwrap()

                setForm({ ...init })
                Swal.fire({
                    title: "SUCCES!",
                    text: "Action réussie",
                    icon: "success"
                });
            }


        } catch (error) {
console.log("error", error)

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Une erreur s'est produite, veuillez réessayer."
            });
        }
    }

    const deleteCat = async (id) => {

        try {
            // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)

            // Affichez une confirmation à l'utilisateur avant la suppression réelle (en utilisant une bibliothèque comme Swal)
            const confirmResult = await Swal.fire({
                title: "Êtes-vous sûr?",
                // text: "Vous ne pourrez pas annuler cette action!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OUI, supprimer!",
                cancelButtonText: "NON !"
            });

            if (confirmResult.isConfirmed) {
                // Supprimez réellement la catégorie
                await deleteData(id).unwrap();

                // Affichez une notification de succès
                Swal.fire({
                    title: "SUCCES!",
                    text: "Action réussie!",
                    icon: "success"
                });
            }
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);

            // Affichez une notification d'erreur
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Une erreur s'est produite lors de la suppression.",
            });
        } finally {
            // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
        }
    };


    const update = value => {
        setForm(value)
    }
    const createLog = async ()=>{
        try {
        
          await createLogPage({content: "Page de creation des solutions attendues de dépôt de plainte"}).unwrap();  
          // Swal.fire({
          //   title: "Log accomplie!",
          //   text: "Action réussie",
          //   icon: "success",
          // });
      } catch (error) {
       
        console.log('error', error)
      }
      }
      const user = useSelector(getUserInfo);
      const isExpired = useSelector(isTokenExpired);
      const dispash = useDispatch();

      useEffect(() => {
        if (isExpired) {
         dispash(logOut())
         window.location.href = "/";
         }  
       }, [isExpired]);
       
    useEffect(() => {
        if (!user?.id) {
            window.location.href = "/";
          }
        createLog()
        switchSkin(skin);
    }, [skin]);

    return (
        <React.Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><NavLink to="/plainte" >Plaintes</NavLink></li>
                            <li className="breadcrumb-item active" aria-current="page">Solution Attendues</li>
                        </ol>
                        <h4 className="main-title mb-0">Liste des solutions attendues</h4>
                    </div>

                </div>


                <div className="container_liste_form">

                    <div className="first_container">
                        <span className="titre_cat">Liste des solutions attendues</span>

                        <hr style={{ width: '100%' }} />

                        <TableSolution
                            solution={solution}
                            solutionAll={solutionAll}
                            solutionOther={solutionOther}
                            deleteCat={deleteCat}
                            setForm={setForm}
                        />

                    </div>





                    <div className="second_container">
                        <span className="titre_cat">Nouvelle solution attendue</span>

                        <hr style={{ width: '100%' }} />

                        <div className="container_form">

                            <div className="container_border">


                                <div className="sous_container_form">



                                    <div className="container_label">
                                        <Form.Label htmlFor="exampleFormControlInput1">
                                            Solution :
                                        </Form.Label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <Form.Control
                                            type="text"
                                            id="exampleFormControlInput1"
                                            value={form.lib_solution}
                                            placeholder="Solution attendue Plainte"
                                            onChange={e => changeForm('lib_solution', e.target.value)}

                                        />
                                    </div>
                                  

                                </div>
                                
                                <div className="container_btn">
                                    <Button variant="primary" onClick={() => setForm({ ...init })}>
                                        Annuler
                                    </Button>

                                    {
                                        loadCreate ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                            :
                                            <Button variant="success" disabled={form.lib_solution == ""} onClick={() => save()}>
                                                Enregister
                                            </Button>
                                    }
                                </div>

                            </div>
                        </div>



                    </div>


                </div>


                <Footer />
            </div>
        </React.Fragment>
    )
}