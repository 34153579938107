import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
// import TablesAffResource from './TablesAffResource.js'
import TableStructurePNM from "./datables/TableStructurePNM.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo, isTokenExpired, logOut } from '../../redux/slice/userSlice';
import { useSelector, useDispatch } from "react-redux";

export default function StructurePNM() {
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const createLog = async () => {
    try {
      await createLogPage({ content: "Page de structure PNM" }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const user = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();

  useEffect(() => {
    if (isExpired) {
     dispash(logOut())
     window.location.href = "/";
     }  
   }, [isExpired]);

  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard-obligation-reglmt">
                  Structure PNM{" "}
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Structure PNM{" "}
              </li>
            </ol>
            <h4 className="main-title mb-0">Structure PNM </h4>
          </div>
        </div>

        {<TableStructurePNM />}
        <Footer />
      </div>
    </React.Fragment>
  );
}
