import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import {
  useUpdatePlainteStatutMutation,
  useCreateNotificationStepChangingMutation,
  useGetDecisionPlainteQuery,
  useUpdateVerdicteMutation,
  useGetVerdicteParPlainteQuery,
  useGetResultatObsQuery,
  useCreateAvisMutation,
  useUpdatePlainteMutation,
  useCreateResObservationMutation,
  useReturnToInstPlainteMutation
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice.js";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  getStatutPlaintes,
  getTypePlaintes,
} from "../../redux/slice/plainteSlice";
import { divIcon } from "leaflet";
import { isEmpty } from "lodash";
import { getUserInfo, verifierAdminAcces} from "../../redux/slice/userSlice.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default function StepsOfComplain({
  motifDeRefus,
  instruirePlainte,
  soumettreRapport,
  Genererrapport,
  setTheshouldIDisplay,
  complainStatus,
  complainId,
  resultatDesInvestigations,
  
}) {

  const statutPlaintes = useSelector(getStatutPlaintes);
  const adminAcces = useSelector(verifierAdminAcces);

  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(false);
  const [button3, setButton3] = useState(false);
  const [seeFonction, setSeeFonction] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  const [editorContent2, setEditorContent2] = useState("");

  const [updatePlainteStatut, { isLoading: loadUpdateState }] = useUpdatePlainteStatutMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  const [sendNotification, { isLoading: loadNotif }] =
    useCreateNotificationStepChangingMutation();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
  useUpdatePlainteMutation();
  const [createAvis] = useCreateAvisMutation();
  const {data: resultatInvestigation = {}} = useGetResultatObsQuery(complainId, {skip: !complainId})
  const [createResultatInvestigation, {isLoading: loadCreate}] = useCreateResObservationMutation()
  const [returnToInstPlainte] = useReturnToInstPlainteMutation();

  const [commentaire, setCommentaire] = useState({});
  const [avis, setAvis] = useState({});
  const handleClose = () => {setShow(false);};
  const handleShow = () => {setShow(true)};
  const handleCloseCloture2 = () => {setShow2(false);};
  const handleShowCloture2 = () => {setShow2(true)};
  const handleCloseCloture3 = () => {setShow3(false);};
  const handleShowCloture3 = () => {setShow3(true)};
  const handleCloseCloture4 = () => {setShow4(false);};
  const handleShowCloture4 = () => {setShow4(true)};
  const [images, setImages] = useState([]);

  const editorRef = useRef();

  const handleChange = (event, editor) => {
    const content = editor.getData()
    setEditorContent(content);
  };
  const handleChange2 = (event, editor) => {
    const content = editor.getData()
    setEditorContent2(content);
  };
  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    setImages(selectedFiles);
  };

  const checkAvis = () => {
    
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

          if (avis?.text) {

            const data = {
              avis : avis?.text ,
              id_plt: complainId,
            }
  
           const retour = await updatePlainte(data).unwrap()
  
            if (retour) {
              window.location.href = "/toutes_les_plaintes";
            }

          } else {
          

            handleCloseCloture2()
            Swal.fire({
        title: "Attention!!!",
        text: "Commentaire vide non autorisé",
        icon: "error",
       
      })


          }

         
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error avis:", error);
    }
  };
  const sendToInstComite = () => {
    handleCloseCloture4()
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const formData = new FormData();

          for (let i = 0; i < images.length; i++) {
            formData.append("images[]", images[i]);
          }
    
            formData.append("motif", editorContent2);
            formData.append("id_plt", complainId);
            formData.append("id_stat_plt", 7);
            
           const retour = await returnToInstPlainte(formData).unwrap()
  
            if (retour) {
              window.location.href = "/toutes_les_plaintes";
            }

          

         
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error avis:", error);
    }
  };

  const coment =(data)=>{
    handleClose()
    try {
   
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

          const retour_plainte = await updatePlainteStatut({
            id_stat_plt: data?.complainStatusId,
            id_plt: data?.mycomplainId,
          }).unwrap();
        
          if (retour_plainte && commentaire?.text) {

            const retour_avis = await createAvis({
              content: commentaire?.text,
              id_plt: data?.mycomplainId,
            }).unwrap();
    
          }
          if (retour_plainte) {
            sendNotification({
              nouveau_status: statutPlaintes.find(
                (etape) => etape.id_stat_plt === data?.complainStatusId
              ),
            });
            window.location.href = "/toutes_les_plaintes";
          } 
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error updating complaint state:", error);
    }
  }
  const getLabel = (status) => {
    switch (status) {
      case 1:
        return "Plainte Soumise";
      case 2:
        return "Réception de Plainte";
      case 3:
        return "Classement de Plainte";
      case 4:
        return "En Complétude D'information";
      case 5:
        return "Plainte Instruite";
      case 6:
        return "Investigation d'une Plainte";
      case 7:
        return "Validation de Résultat D'investigation";
      case 8:
        return "Plainte En Rapport D'investigation";
      case 9:
        return "Avis de Règlement d'une Plainte";
      case 10:
        return "Clôture d'une Plainte";
      case 11:
        return "Plainte Traitée";
      default:
        return "Plainte Classée";
    }
  };
  const createLogActionPlainte = async (status)=>{
    try {
      await createLogPage({content: getLabel(status)}).unwrap();    
  } catch (error) {
    console.log('error', error)
  }
  }

  const updateComplainState = async (complainStatusId, mycomplainId) => {
   if (complainStatusId === 10) {

    setCommentaire({...commentaire, complainStatusId, mycomplainId})
    handleShow()
   } else {
    try {

      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const retour_plainte = await updatePlainteStatut({
            id_stat_plt: complainStatusId,
            id_plt: mycomplainId,
          }).unwrap();
          createLogActionPlainte(complainStatusId)
          if (retour_plainte) {
            sendNotification({
              nouveau_status: statutPlaintes.find(
                (etape) => etape.id_stat_plt === complainStatusId
              ),
            });
            window.location.href = "/toutes_les_plaintes";
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error updating complaint state:", error);
    }
   }
  };

  const soustitreStyle = {
    fontSize: "1.7em", // Taille de la police
    fontWeight: "bolder", // Gras
    // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const upload = (statut) => {
    const formData = new FormData();
    formData.append("comment_res", editorContent);
    formData.append("id_plt", complainId);
   
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          let result;
            result = await createResultatInvestigation(formData).unwrap();
            createLogActionPlainte(statut)
          if (result) {
            const retour_plainte = await updatePlainteStatut({
              id_stat_plt: statut,
              id_plt: complainId,
            }).unwrap();
            if (retour_plainte) {
    

              sendNotification({
                nouveau_status: statutPlaintes.find(
                  (etape) => etape.id_stat_plt === statut
                ),
              });
              window.location.href = "/toutes_les_plaintes";
            }
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error creating obs state:", error);
    }
  };

  const user = useSelector(getUserInfo);

  useEffect(() => {    
    if (user?.profil == adminAcces) {
      setSeeFonction(true)
    } else {
      setSeeFonction(false)
    }
  }, []);

  return (
    <div className="container">
      <div className="row">
        {statutPlaintes.map((item, key) => {
          let buttonState = <></>;
          if (complainStatus === item.id_stat_plt) {
            if (complainStatus === 1) {
              buttonState = (
                <Button
                  onClick={() => {
                    updateComplainState(2, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Accuser réception de la plainte</span>
                  )}
                </Button>
              );
            } else if (complainStatus === 2) {
              buttonState = (
                <div class="row">
                  <div className="col-4">
                    {" "}
                    <Button
                      onClick={() => {
                        handleShowCloture3()
                      }}
                      variant="danger"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Classer la plainte</span>
                      )}
                    </Button>
                  </div>
                  <div className="col-4 text-center">
                    {" "}
                    <Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(true);
                        setButton3(false);
                        setTheshouldIDisplay();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Compléter des informations</span>
                      )}
                    </Button>
                  </div>
                  <div className="col-4 text-right">
                    {" "}
                    <Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(false);
                        setButton3(true);
                        instruirePlainte();
                      }}
                    >
                      {loadUpdateState && button3 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Instruire la plainte</span>
                      )}
                    </Button>
                  </div>
                </div>
              );
            } else if (complainStatus === 3) {
              buttonState = (
                <h1 style={{ color: "red", fontSize: "14px" }}>
                  Cette plainte a déjà été classée
                </h1>
              );
            } else if (complainStatus === 4) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        setTheshouldIDisplay();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Compléter des informations</span>
                      )}
                    </Button>
                  </div>

                  <div className="col-6 text-right" >
                    { seeFonction &&
                      <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        instruirePlainte();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Instruire la plainte </span>
                      )}
                    </Button>}
                  </div>
                </div>
              );
            } else if (complainStatus === 5) {
              buttonState = (
                <Button
                  onClick={() => {
                    setButton1(true);
                    setButton2(false);
                    updateComplainState(6, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span> Démarrer l'investigation </span>
                  )}
                </Button>
              );
            } else if (complainStatus === 6) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        resultatDesInvestigations();
                      }}
                      variant="success"
                    >
                      {loadUpdateState ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Entrer le résultat d'investigation </span>
                      )}
                    </Button>
                  </div>

                  {!isEmpty(resultatInvestigation) && 
                    <div className="col-6 text-right">
                      <Button
                        onClick={() => {
                          setButton1(false);
                          setButton2(true);
                          updateComplainState(7, complainId);
                        }}
                        variant="success"
                      >
                        {loadUpdateState && button2 ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span> Valider le résultat d'investigation </span>
                        )}
                      </Button>
                    </div>
                  }

                </div>
              );
            } else if (complainStatus === 7) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                   { seeFonction &&
                   <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(true);
                        Genererrapport();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Proposer une action</span>
                      )}
                    </Button>}
                  </div>

                  <div className="col-6 text-right">
                    {
                      seeFonction &&<Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(true);
                        motifDeRefus();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Repasser en étape d'investigation</span>
                      )}
                    </Button>}
                  </div>
                </div>
              );
            } else if (complainStatus === 8) {
              buttonState = (
                <Button
                  onClick={() => {
                    updateComplainState(9, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span> Passer en étape de règlement </span>
                  )}
                </Button>
              );
            } else if (complainStatus === 9) {
              buttonState = (
                <div className="d-flex flex-row justify-content-around">
                  {
                    seeFonction &&<Button
                  onClick={()=> Genererrapport()}
                  >
                    Modifier Action
                  </Button>}
                  {
                    seeFonction &&<Button
                  onClick={()=> handleShowCloture4()}
                
               variant="info"
               style={{color:"white"}}
                  >
                    Ramener en Instruction
                  </Button>}
                 {  seeFonction &&<Button
                    onClick={() => {
                      setButton1(false);
                      setButton2(true);
                      updateComplainState(10, complainId);
                    }}
                    variant="success"
                  >
                    {loadUpdateState ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span> Clôturer la plainte </span>
                    )}
                  </Button>}
                </div>
              );
            } else if (complainStatus === 10) {
              buttonState =  seeFonction && <Button onClick={()=>handleShowCloture2()} class="text-center " variant="secondary"  style={soustitreStyle}>Avis de satisfaction</Button>;
            } else {
              buttonState = seeFonction && <Button onClick={()=>handleShowCloture2()} class="text-center" variant="secondary" style={soustitreStyle}>Avis de satisfaction</Button>;
            }
            }

          return buttonState;
        })}
      </div>

      <Modal show={show} onHide={handleClose} size="lg" className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Commentaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2">
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}

              <Form.Control
            onChange={(v)=>setCommentaire({...commentaire, text :v.target.value })}
            as="textarea"
            value={commentaire.text}
            id="cmt"
            rows="5"
            placeholder="Entrer votre commentaire..."
          ></Form.Control>
            </Col>
          
          </Row>
          <Row>
          <Col className="text-end">
              <Button variant="success" onClick={()=>coment(commentaire)}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* ---------------------Avis----------------------------- */}
      <Modal show={show2} onHide={handleCloseCloture2} size="lg" className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Avis de satisfaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2">
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}

              <Form.Control
            onChange={(v)=>setAvis({...avis, text :v.target.value })}
            as="textarea"
            value={avis.text}
            id="cmt"
            rows="5"
            placeholder="Entrer votre avis..."
          ></Form.Control>
            </Col>
          
          </Row>
          <Row>
          <Col className="text-end">
              <Button variant="success" onClick={()=>checkAvis()}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* ---------------------Commentaire Classé----------------------------- */}
      <Modal show={show3} onHide={handleCloseCloture3}  className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Votre commentaire de classement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}
              <Form.Group controlId="formBasicText">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent}
                      onReady={(editor) => editorRef.current = editor}
                      onChange={handleChange}
                    />
                  </Form.Group>
              
            </Col>
          
          </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={()=>handleCloseCloture3()}>
                Fermer
              </Button>
              <Button variant="success" disabled={editorContent==""} onClick={()=>upload(3)}>
                Valider
              </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleCloseCloture4}  className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Commentaire de renvoi en instruction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}
              <Form.Group controlId="formBasicText">
                    <CKEditor
                      editor={ClassicEditor}
                      data={editorContent2}
                      onReady={(editor) => editorRef.current = editor}
                      onChange={handleChange2}
                    />
                  </Form.Group>
              
            </Col>
          
          </Row>
          <Row className="mb-5">
                <Col lg="1"></Col>
                <Col lg="10">
                <Form.Label htmlFor="img">
            Pièces jointes
          </Form.Label>
          <Form.Control
            onChange={handleFileChange}
            type="file"
            id="img"
            placeholder=""
            name="images"
            multiple
            style={{
              border: "1px solid #1f365c",
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
              borderRadius: "5px",
              padding: "8px"
            }}
          />
                </Col>
                <Col lg="1"></Col>

              </Row>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={()=>handleCloseCloture4()}>
                Fermer
              </Button>
              <Button variant="success" disabled={editorContent2==""} onClick={()=>sendToInstComite()}>
                Valider
              </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
