import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import TableCatFaq from "./dataTable/TableCatFaq";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  useGetCatFaqQuery,
  useCreateCatFaqMutation,
  useUpdateCatFaqMutation,
  useDeleteCatFaqMutation,
} from "../../redux/apiSlice/categorieFaqApiSlice";
import Swal from "sweetalert2";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo, isTokenExpired, logOut } from "../../redux/slice/userSlice";
import { useSelector, useDispatch } from "react-redux";

export default function Plateforme() {
  const {
    data: catFaq,
    refetch: refetchCatFaq,
    isLoading: loadCatFaq,
  } = useGetCatFaqQuery();
  const [createCatFaq, { isLoading: loadCreateCatFaq }] =
    useCreateCatFaqMutation();
  const [updateCatFaq, { isLoading: loadUpdateCatFaq }] =
    useUpdateCatFaqMutation();
  const [deleteCatFaq] = useDeleteCatFaqMutation();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  // modal
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de creation de catégorie de FAQ",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const user = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();
  
  useEffect(() => {
  if (isExpired) {
  dispash(logOut())
  window.location.href = "/";
  }  
  }, [isExpired]);

  useEffect(() => {
    createLog();
  }, []);

  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  const initCatFaq = {
    id_cat_rens: null,
    lib_cat_rens: "",
  };

  const [creerCatFaq, setCreerCatFaq] = useState({
    ...initCatFaq,
  });
  const [idCatFaq, setIdCatFaq] = useState(0);

  const isRequired = creerCatFaq.lib_cat_rens === "";

  const [errorMsg1, setErrorMsg1] = useState({
    lib_cat_rens: "",
  });

  const informationMessage = {
    lib_cat_rens: "Veuillez saisir le libellé",
  };

  const afficherMessage = (objetMsg) => {
    Object.entries(objetMsg).forEach(([key, message]) => {
      if (creerCatFaq[key] === "") {
        toast.warning(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const recupererChamp = (field, value) => {
    setCreerCatFaq({ ...creerCatFaq, [field]: value });

    if (field === "lib_cat_rens" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        lib_cat_rens: "Veuillez saisir le libellé",
      }));
    } else {
      setErrorMsg1((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };

  const viderChamps = () => {
    setCreerCatFaq({ ...initCatFaq });
  };
  const saveCatFaq = async () => {
    try {
      if (creerCatFaq.id_cat_rens)
        await updateCatFaq({
          ...creerCatFaq,
          id_cat_rens: creerCatFaq.id_cat_rens,
          lib_cat_rens: creerCatFaq.lib_cat_rens,
        }).unwrap();
      else
        await createCatFaq({
          lib_cat_rens: creerCatFaq.lib_cat_rens,
        }).unwrap();
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success",
      });

      refetchCatFaq();
      setCreerCatFaq({ ...initCatFaq });
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "Erreur lors de la creation!",
      });
    }
  };

  const suppressionFaq = async () => {
    try {
      let retour_supp = await deleteCatFaq({
        id_cat_rens: idCatFaq,
      }).unwrap();
      if (retour_supp) {
        refetchCatFaq();
        handleCloseDelete();
        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success",
        });
      }
    } catch (error) {
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: "Erreur lors de la suppression",
      });
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Gestion des categories de renseignement
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Liste des categories de renseignement à de Arcep
            </h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">
              Liste des categories de renseignement
            </span>

            <hr style={{ width: "100%" }} />
            {loadCatFaq ? (
              <p className="w-100 d-flex flex-row justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </p>
            ) : (
              <TableCatFaq
                creerCatFaq={creerCatFaq}
                setCreerCatFaq={setCreerCatFaq}
                catFaq={catFaq.data}
                handleShowDelete={handleShowDelete}
                setIdCatFaq={setIdCatFaq}
              />
            )}
            {/* {!loadCatFaq && (
              <TableCatFaq
                creerCatFaq={creerCatFaq}
                setCreerCatFaq={setCreerCatFaq}
                catFaq={catFaq.data}
                handleShowDelete={handleShowDelete}
                setIdCatFaq={setIdCatFaq}
              />
            )} */}
          </div>

          <div className="second_container">
            <span className="titre_cat">
              Nouvelle categorie de renseignement
            </span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Libellé * :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="libellé"
                      value={creerCatFaq.lib_cat_rens}
                      onChange={(e) =>
                        recupererChamp("lib_cat_rens", e.target.value)
                      }
                    />
                    {errorMsg1.lib_cat_rens && (
                      <span className="text-danger">
                        {errorMsg1.lib_cat_rens}
                      </span>
                    )}
                  </div>
                </div>

                <div className="container_btn">
                  <Button variant="danger" onClick={() => viderChamps()}>
                    Annuler
                  </Button>

                  <Button
                    variant="success"
                    // onClick={() => saveCatFaq()}
                    // disabled={
                    //   isRequired || loadCreateCatFaq || loadUpdateCatFaq
                    // }
                    disabled={loadCreateCatFaq || loadUpdateCatFaq}
                    onClick={() =>
                      isRequired
                        ? afficherMessage(informationMessage)
                        : saveCatFaq()
                    }
                  >
                    {loadCreateCatFaq || loadUpdateCatFaq ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>Enregister</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------modal de modification---------- */}
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Etês-vous sûr de vouloir supprimer ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Annuler
            </Button>
            <Button variant="danger" onClick={suppressionFaq}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
