import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal, Table } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import "./styleProfile.css";
import {
  useGetEcheancesQuery,
  useUpdateEcheanceMutation,
  useDeleteEcheanceMutation,
} from "../../redux/slice/echeanceApiSlice";
import { useNavigate, useParams } from "react-router-dom";

import { useGetModulesQuery } from "../../redux/apiSlice/moduleApiSlice";
import { useGetFonctionnaliteQuery } from "../../redux/apiSlice/fonctionnalitésApiSlice";
import { useCreateProfilMutation } from "../../redux/apiSlice/profileApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import frCH from "date-fns/locale/fr-CH";
import { getUserInfo, isTokenExpired, logOut } from '../../redux/slice/userSlice';
import { useSelector, useDispatch } from "react-redux";

export default function Profile() {
  const navigate = useNavigate()
  const { data: modules = [], isLoading: loadModules } = useGetModulesQuery();
  const { data: fonctionnalite = [], isLoading: loadFonc } =
    useGetFonctionnaliteQuery();

  const [modulesPage, setModulesPage] = useState([]);
  const [modulesPage2, setModulesPage2] = useState([]);
  const [modulesTemoin, setModulesTemoin] = useState([]);
  

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const init = {
    lib_echeance: "",
  };
  const [form, setForm] = useState({ ...init });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const { data: echeance = [], isLoading: loadTypeObligations } =
    useGetEcheancesQuery();
  const [createData, { isLoading: loadCreate }] = useCreateProfilMutation();
  const [updateData, { isLoading: loadUpdate }] = useUpdateEcheanceMutation();
  const [deleteData, { isLoading: loadDelete }] = useDeleteEcheanceMutation();

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const [etatAll, setEtatAll] = useState(false);
  const [nomProfil, setNomProfil] = useState();
  const [fonc, setFonc] = useState([]);
  const [fonc2, setFonc2] = useState([]);
  const [temoinFonc, setTemoinFonc] = useState([]);
  const [etatFonc, setEtatFonc] = useState(0);



  const changeForm = (field, value) => setForm({ ...form, [field]: value });

  const createProfil = async () => {

    if (nomProfil == undefined) {
         Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Veuillez saisir le nom du profil",
      });
    
      
    }else {
      const retr = modulesPage.map((item)=>{

       const tab = item.fonc.filter((fc)=>fc.value == true)
        
        return{
              ...item, fonc : tab
        }
  
      })

      const final = retr.map((item)=>{

        const monObj = item.fonc.map((fc)=>(fc.id_fonc))
            
        return {
          module : item.id_mod,
          fonc : monObj
        }
      })
  
    const  data = {
        nom_profil : nomProfil,
        mod_insert : final
      }

  
      try {

          await createData(data).unwrap();
          setForm({ ...init });
          Swal.fire({
            title: "SUCCES!",
            text: "Action réussie",
            icon: "success",
          });
        
          setNomProfil("")
          setModulesPage(modulesTemoin)
      } catch (error) {
   
        console.log('error', error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur s'est produite",
        });
      }
    }

    
  };

  const deleteEcheance = async (id) => {
    try {
      // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)

      // Affichez une confirmation à l'utilisateur avant la suppression réelle (en utilisant une bibliothèque comme Swal)
      const confirmResult = await Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas annuler cette action!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      });

      if (confirmResult.isConfirmed) {
        // Supprimez réellement la catégorie
        const retour = await deleteData(id).unwrap();

        // Affichez une notification de succès
        Swal.fire({
          title: "SUCCES!",
          text: "Action réussie",
          icon: "success",
        });
      }
    } catch (error) {


      // Affichez une notification d'erreur
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        // text: error.data.message,
        // footer: '<a href="#">Essayer une autre fois?</a>'
      });
    } finally {
      // Mettez à jour l'état de chargement ici si nécessaire (loadDelete)
    }
  };

  const handleChange = (id_fonc, value) => {
     
    switch (id_fonc) {
      case "tous":
        setEtatAll(value)
        const objVal = fonc2.map((item)=>{
           return{
             ...item, value : value
           }    
 
       })

       setFonc(objVal)
       setFonc2(objVal)

        break;
    
      default:

      const objtrv = fonc2.map((item)=>{

         if (item.id_fonc === id_fonc) {
          return{
            ...item, value : value
          }
         } else {
          return{
            ...item
          }
         }

      })

setFonc(objtrv)
setFonc2(objtrv)

        break;
    }
  };

const loadData = ()=>{

  setNomProfil("")
  setFonc([])
  setEtatFonc(1)
}
 

  const chargeFonc = (id_mod)=>{

     setEtatFonc(1)
     setEtatAll(false)
    const resul = temoinFonc.filter((item)=>(item.id_mod ==id_mod ))
   
    setFonc(resul)
    setFonc2(resul)

  }

  const ajoutMod = ()=>{

   const resul =  modulesPage.map((item)=>{

    const tab =  item.fonc.map((fc)=>{
        const rsl = fonc.find((elt)=>(elt.id_fonc == fc.id_fonc))
  
    if (rsl) {
      return {
        ...fc,
        value: rsl.value
      };
    }else{  
        return  fc}
      })

      return  {
        ...item,
        fonc : tab
      }
    })

    setEtatFonc(0)
   setFonc([])
    setModulesPage(resul)
    setModulesPage2(resul)

  }

  const retraitFonc = (id_mod, id_fonc)=>{
    
    const rtr = modulesPage2.map((item)=>{

      if(item.id_mod == id_mod){
        
        const rsul =  item.fonc.map((fc)=>{
            if(fc.id_fonc == id_fonc){
            return{...fc, value : false}
            }else{
              return fc
            }
          })
      return {...item, fonc : rsul}

        }else{
return item
        }
    })

setModulesPage(rtr)
setModulesPage2(rtr)
    
        }

  const createLog = async ()=>{
    try {
    
      await createLogPage({content: "Page de creation de profil"}).unwrap();  
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
  } catch (error) {
   
    console.log('error', error)
  }
  }      
   

  switchSkin(skin);
  const user = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();
  
      useEffect(() => {
      if (isExpired) {
      dispash(logOut())
      window.location.href = "/";
      }  
      }, [isExpired]);
      
  useEffect(() => {
    createLog()   
  }, []);
  
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }  
  }, []);
  

  useEffect(() => {
 
    const tabMod = modules.map((item) => {
      return {
        id_mod: item.id_mod,
        lib_mod: item.lib_mod,
        fonc: item.Fonctionnalites.map((fonc) => {
          return {
            id_fonc: fonc.id_fonc,
            lib_fonc: fonc.lib_fonc,
            id_mod: fonc.id_mod,
            value : false
          };
        }),
      };
    });

   
    setModulesPage(tabMod);
    setModulesTemoin(tabMod)
    const tabRetour = fonctionnalite.map((item) => {
      return {
        ...item, value : false
      };
    });

    setTemoinFonc(tabRetour);

    switchSkin(skin);
  }, [skin, modules, fonctionnalite]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink >
                  Administration
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Création de profil
              </li>
            </ol>
           
           
          </div>
        </div>
   <div className="container row">
    <div className="col breadcrumb-item active">
    <h4 className="main-title mb-0">Gestion des profils</h4>
    </div>
    <div className="col text-right">
    <Button variant="success" onClick={()=> navigate("/liste-profil")}>
                    Liste des profils
      </Button>
    </div>
   </div>
        <div className="container_liste_form">
          <div className="second_container">
            <span className="titre_cat">Nouvel profil</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form_profile">
          
    
            <Form.Label htmlFor="lib">Nom profil</Form.Label>
                  <Form.Control
                                           value={nomProfil}
                                           type="text"
                                            id="lib"
                                         onChange={(v)=>setNomProfil(v.target.value)}
                                            
                                        />
     <Form.Label htmlFor="lib">Modules</Form.Label>
                <Form.Select 
                                            aria-label="Default select example"
                                            // value={form.id_tranche}
                                          onChange={(v)=> chargeFonc(v.target.value)}
                                        >
                                            {
                                        
                                               modules.map((mod, i) => (
                                                <option key={i} value={mod.id_mod}>{mod.lib_mod}</option>
                                            ))
                                    
                                                }
                                            
                                        
            </Form.Select>
            { etatFonc == 1 && fonc.length >0 &&
            <>
     <Form.Label htmlFor="lib" className="mt-5">Fonctionnalités</Form.Label>
              
  
     <Table className="mb-0">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Intitulé</th>
      <th className="text-center" scope="col">Cocher pour choisir</th>
    </tr>
  </thead>
  <tbody>
  <tr >
    <th scope="row">-</th>
    <th scope="row">Toutes les fonctionnalités</th>
    <td className="text-center">
      <Form.Check 
      type="checkbox"
      checked={etatAll}
      onChange={(v)=>handleChange('tous', v.target.checked)}
      />
      </td>
   
    </tr>
{
  fonc.map((item, index)=>(
    <tr key={item.id_fonc}>
    <td scope="row">{index+1}</td>
    <td scope="row">{item.lib_fonc}</td>
    <td className="text-center">
      <Form.Check 
      type="checkbox"
      checked={item.value}
      onChange={(v)=>handleChange(item.id_fonc, v.target.checked)}
      
      />
      </td>
    
    </tr>
  ))
}



 
  </tbody>
</Table>
</>
}
   
                                             
                </div>

                <div className="container_btn">
                  <Button
                    variant="primary"

                    onClick={()=> loadData()}
                  >
                    Annuler
                  </Button>

                  <Button variant="danger" onClick={() => ajoutMod()}>
                    Ajouter
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="first_container">

        
            <span className="titre_cat">Accès profil</span>
          

          


            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">
                <div className="sous_container_form_profile">
          
    
            <Form.Label htmlFor="lib">Nom profil</Form.Label>
                  <Form.Control
                                            type="text"
                                            id="lib"
                                       value={nomProfil}
                                       disabled
                                            
                                        />
    
     <Form.Label htmlFor="lib" className="mt-5">Accès données</Form.Label>
 
              
     <Table className="mb-0">
  <thead>
    <tr>
      <th scope="col">Modules</th>
      <th scope="col">Fonctionnalités</th>

    </tr>
  </thead>
  <tbody>
    {modulesPage.map((item) => (
      <tr key={item.id_mod}>
        <td scope="row">{item.lib_mod}</td>
        <td scope="row">
          {item.fonc.map((fc) => (
            <div key={fc.id_fonc}>
              {fc.value == true && (
                <div 
                style={{display :'flex', flexDirection : 'row', justifyContent: 'center', alignItems : "center", cursor : "pointer", gap: 4 }} 
                onClick={()=>retraitFonc(item.id_mod, fc.id_fonc)}>
                 <span>{fc.lib_fonc}</span>
                  <i className="ri-close-circle-fill" style={{color :'red'}}></i>
        
                </div>
              )}
            </div>
          ))}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

   
                                             
                </div>

                <div className="container_btn">
                  <Button
                    variant="primary"
                    onClick={() => setModulesPage(modulesTemoin)}
                  >
                    Annuler
                  </Button>
                  
                  <Button variant="danger" onClick={()=>createProfil()} >
                    Accorder
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
