import React, { useState, useEffect } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.jpg";
import Iso from "../assets/img/iso.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLoginMutation } from "../redux/apiSlice/loginApiSlice";
import { createCookie } from "../helpers";
import { getEtatLocal, getUserInfo, setUser, } from "../redux/slice/userSlice";
import { SET_ID_USER, SET_ACTIVE_USER } from "../redux/slice/infoUserSlice";

export default function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const toaster = useToaster();
  const [login, { isLoading: loadLogin }] = useLoginMutation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const dispash = useDispatch();

  const redirect = (acces) => {
    if (acces && acces) {
      for (let i = 0; i < acces.length; i++) {
        const droit = acces[i];
        switch (droit) {
          case 48:
            navigate("/dashboard");
            setLoading(false);
            return;
          case 134:
            navigate("/dashboard-plainte");
            setLoading(false);
            return;
          case 135:
            navigate("/dashboard-numerotation");
            setLoading(false);
            return;
          case 136:
            navigate("/dashboard-obligation");
            setLoading(false);
            return;
          default:
            break;
        }
      }
    }
  };

  const connexion = async () => {
    setLoading(true);
    try {
      const user = await login({
        email_user: email,
        password: password,
      }).unwrap();
      
      const userData = {
        name: user?.name,
        organisme: user?.organisme,
        id_profil: user?.id_profil,
        profil: user?.profil,
        id: user?.id_user,
        token: user?.token,
        id_operateur: user?.id_operateur,
        email,
      };
      
      dispatch(setUser(userData));
      createCookie("userInfo", JSON.stringify(userData));
      redirect(user?.acces_dashboard?.fonc);
    } catch (error) {
      setLoading(false);
      toast.error("Authentification échouée.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const disabled = email === "" || password === "";

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="d-flex flex-row justify-content-center">
            <Image
              src={Iso}
              alt=""
              style={{ height: "100px", width: "160px" }}
            />
          </Link>

          <>
            <Card.Title className="d-flex flex-row justify-content-center mt-4">
              Connexion
            </Card.Title>
            <Card.Text>
              Bienvenue sur PIGRU. Veuillez vous authentifier
            </Card.Text>
          </>
        </Card.Header>
        <Card.Body>
          <Form>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="admin34@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password{" "}
              </Form.Label>
              <Form.Control
                // type={visible ? "text" : "password"}
                type="password"
                id="password"
                placeholder="**********"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
              />
            </div>
            <div className="container row mb-3">
              <div
                className="col text-right"
                onClick={() => navigate("/send_email_recuperation")}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Mot de passe oublié ?
              </div>
            </div>
          </Form>

          <Button
            variant="primary"
            className="btn-sign"
            disabled={disabled || loadLogin}
            onClick={() => connexion()}
            loading={loadLogin}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Se connecter</span>
            )}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
