import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
// import TablesAffResource from './TablesAffResource.js'
import TableRessources from "./datables/TableRessourcesStat.js";
import Spinner from "react-bootstrap/Spinner";
import {
  useGetSuiviObligationsQuery,
  useUpdateSuiviObligationMutation,
} from "../../redux/slice/suiviApiSlice.js";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice";
import { useGetTypeOperateursQuery } from "../../redux/slice/typeOperateurApiSlice";
import Swal from "sweetalert2";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";
import { getUserInfo } from '../../redux/slice/userSlice';
import { useSelector } from "react-redux";
import {
  useCreateAttribuerServiceTrancheMutation,
  useDeleteAttribuerServiceTrancheMutation,
  useGetAttribuerServiceTrancheQuery,
  useGetServiceTrancheQuery,
  useGetSousTrancheQuery,
  useGetTrancheQuery,
  useUpdateAttribuerServiceTrancheMutation,
} from "../../redux/apiSlice/numerotationApiSlice";

export default function SuiviOR() {
  const { data, isLoading } = useGetAttribuerServiceTrancheQuery();
  const { data: tranches = [], isLoading: loadTranches } = useGetTrancheQuery();
  const { data: sousTranches = [], isLoading: loadSousTranches } =
    useGetSousTrancheQuery();
  const { data: services = [], isLoading: loadServices } =
    useGetServiceTrancheQuery();
  const [createAttribuerServiceTranche] =
    useCreateAttribuerServiceTrancheMutation();
  const [updateAttribuerServiceTranche] =
    useUpdateAttribuerServiceTrancheMutation();
  const [deleteAttribuerServiceTranche] =
    useDeleteAttribuerServiceTrancheMutation();

  // modal
  const [show, setShow] = useState(false);

  const [id_ob_op, setId_ob_op] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setId_ob_op(id);
    setShow1(true);
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [listeTab, setListeTab] = useState([]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const {
    data: listeSuivi = [],
    refetch: refSuivi,
    isLoading: loadListeSuivi,
  } = useGetSuiviObligationsQuery();

  const { data: operateur = [], isLoading: loadOperateur } =
    useGetOperateursQuery();

  const {
    data: typeOperateur = [],
    refetch: refetchTypeOp,
    isLoading: loadTypeObligations,
  } = useGetTypeOperateursQuery();

  const [updateData, { isLoading: loadUpdate }] =
    useUpdateSuiviObligationMutation();
  const save = async () => {
    try {
      await updateData(id_ob_op).unwrap();
      handleClose1();
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success",
      });
      setId_ob_op("");
      refSuivi();
    } catch (error) {
      Swal.fire({
        icon: "ERREUR",
        title: "Oops...",
        text: error.data.errors[0].message,
      });
    }
  };

  switchSkin(skin);

  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de gestion des états PNM",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const user = useSelector(getUserInfo);
  useEffect(() => {
    createLog()   
  }, []);

  useEffect(() => {
   if (!user?.id) {
      window.location.href = "/";
    }  
  }, []);
  
  useEffect(() => {
    const objetAff = listeSuivi.filter(
      (item) =>
        new Date(item.date_vigueur) - new Date() < 0 ||
        new Date(item.date_vigueur) - new Date() == 0
    );

    function ajouterJours(dateStr, jours) {
      // Convertir la chaîne de date en objet Date
      const date = new Date(dateStr);

      // Ajouter le nombre de jours à la date
      date.setDate(date.getDate() + jours);

      // Formater la nouvelle date en "YYYY-MM-DD"
      const nouvelleDate = date.toISOString().split("T")[0];

      return nouvelleDate;
    }

    function joursRestants(date) {
      // Convertir la chaîne de date en objet Date
      const dateFin = new Date(date);

      // Obtenir la date actuelle
      const maintenant = new Date();

      // Calculer la différence en millisecondes entre les deux dates
      const differenceEnMillisecondes = dateFin - maintenant;

      // Calculer le nombre de jours restants
      const differenceEnJours =
        differenceEnMillisecondes / (1000 * 60 * 60 * 24);

      // Utiliser Math.floor pour obtenir un nombre entier de jours
      const joursRestants = Math.floor(differenceEnJours);

      return joursRestants;
    }

    const tabSuivi = objetAff.map((item) => {
      const datEch = ajouterJours(
        item.date_vigueur,
        parseInt(item?.Obligation_reg?.Periodicite?.lib_periode)
      );

      if (datEch) {
        const jrest = joursRestants(datEch);
        return {
          id_ob_op: item.id_ob_op,
          date_vigueur: item.date_vigueur,
          statut: item.statut,
          jrsRst: jrest,
          dat_echeance: datEch,
          id_operateur: item?.id_operateur,
          lib_obligation_reg: item.Obligation_reg?.lib_obligation_reg,
          id_type_op: item.Obligation_reg?.id_type_op,
          lib_type_op: item.Obligation_reg?.Type_operateur?.lib_type_op,
          id_type_op: item.Obligation_reg?.id_type_op,
        };
      }
    });

    const rsulFil = tabSuivi.map((item) => {
      const op = operateur.find((op) => item.id_operateur === op.id_operateur);

      // Si on trouve un opérateur correspondant, on retourne un nouvel objet avec le nom de l'opérateur
      if (op) {
        return { ...item, operateur: op?.nom_operateur };
      }

      // Sinon, on retourne simplement l'élément original
      return { ...item, operateur: null };
    });
    // console.log("rsulFil", rsulFil)
    setListeTab(rsulFil);
    switchSkin(skin);
  }, [skin, listeSuivi]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard-obligation-reglmt">
                  Numerotation
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Etat PNM
              </li>
            </ol>
            <h4 className="main-title mb-0">Etat PNM</h4>
          </div>
        </div>

        {
          <TableRessources />
    
        }


        <Footer />
      </div>
    </React.Fragment>
  );
}
