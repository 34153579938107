import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col, Row } from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetPlainteAvecOpDateQuery,
  useGetStatistiquePlainteGlobalQuery,
  useGetAllPlainteQuery,
  useGetAllServiceQuery,
  useGetTypePlainteQuery,
  useGetCanauxReceptionQuery,
  useGetTypeOperateurQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

import {
  useGetDecisionPlainteQuery,
  useGetStatutPlaintesQuery,
  useGetTypePlaintesQuery,
  useGetTypeRequeteQuery,
} from "../../../redux/apiSlice/plainteApiSlice.js";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";

import { getTypePlaintes } from "../../../redux/slice/plainteSlice.js";
import TablePlainte from "./dataTables/TablePlainte.js";
import { useCreateLogPageMutation } from "../../../redux/apiSlice/logPageApiSlice.js";
export default function StatistiquePlainte() {
  const { data: opPlainte } = useGetOperateursQuery();
  const { data: allPlainte } = useGetAllPlainteQuery();
  const { data: allDecision } = useGetDecisionPlainteQuery();
  const { data: allService } = useGetAllServiceQuery();
  const { data: statustsPlainte } = useGetStatutPlaintesQuery();
  const { data: typePlaintes } = useGetTypePlaintesQuery();
  const { data: canauxRecept } = useGetCanauxReceptionQuery();
  const { data: typeOp } = useGetTypeOperateurQuery();
  const { data: typeRequete = [], isLoading: loadTypeRequete } =
    useGetTypeRequeteQuery();
  const initPlainte = {
    lib_sexe: "",
    id_stat_plt: "",
    id_type_op: "",
    id_operateur: "",
    id_type_req: "",
    id_type_plt: "",
    id_service: "",
    id_dec: "",
    id_canal: "",
    date_debut: "",
    date_fin: "",
  };
  const [lesChamp, setLesChamp] = useState({ ...initPlainte });
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOptionQualification, setSelectedOptionQualification] =
    useState([]);
  const [tableOp, setTableOp] = useState([]);
  const [tableQualification, setTableQualification] = useState([]);
  const [tableStatuts, setTableStatuts] = useState([]);
  const [selectedOptionStatuts, setSelectedOptionStatuts] = useState([]);
  const [tableServices, setTableServices] = useState([]);
  const [selectedOptionServices, setSelectedOptionServices] = useState([]);
  const [tableCatPlainte, setTableCatPlainte] = useState([]);
  const [selectedOptionCatPlainte, setSelectedOptionCatPlainte] = useState([]);
  const [tableSourceProvenance, setTableSourceProvenance] = useState([]);
  const [selectedOptionSourceProvenance, setSelectedOptionSourceProvenance] =
    useState([]);
  const [tableDecision, setTableDecision] = useState([]);
  const [selectedOptionDecision, setSelectedOptionDecision] = useState([]);
  const [tableSexe, setTableSexe] = useState([]);
  const [selectedOptionSexe, setSelectedOptionSexe] = useState([]);

  const lesSexes = [
    {
      value: "M",
      label: "Masculin",
    },
    {
      value: "F",
      label: "Féminin",
    },
  ];
  const lesPlaintes = allPlainte?.filter((plt) => plt.id_type_req === 2);
  const viderEtRafraichi = () => {
    setLesChamp({ ...initPlainte });
    setDataPlainte(lesPlaintes);
  };

  const [timestamps, setTimestamps] = useState({
    date_debut: "",
    date_fin: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    msgDebut: "",
    msgFin: "",
  });
  const [dataPlainte, setDataPlainte] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plainteFondeeOuNon, setPlainteFondeeOuNon] = useState("");

  const recuperateurGeneral = (champ, value) => {
    setLesChamp({ ...lesChamp, [champ]: value });
    const timestamp = new Date(value).getTime();

    if (champ === "date_debut") {
      if (timestamps.date_fin && timestamp > timestamps.date_fin) {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "Date de début supérieure à date de fin.",
          msgFin: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "",
          msgFin: "",
        });
      }
    }

    if (champ === "date_fin") {
      if (timestamps.date_debut && timestamp < timestamps.date_debut) {
        setErrorMessage({
          ...errorMessage,
          msgFin: "Date de fin inférieure à date de début.",
          msgDebut: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgFin: "",
          msgDebut: "",
        });
      }
    }

    setTimestamps({
      ...timestamps,
      [champ]: timestamp,
    });
  };
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();

  useEffect(() => {
    const createLog = async () => {
      try {
        await createLogPage({
          content: "Statistique plainte",
        }).unwrap();
      } catch (error) {
        console.log("error", error);
      }
    };
    createLog();
  }, []);

  useEffect(() => {
    if (allPlainte) {
      setDataPlainte(() => {
        return lesPlaintes.map((j) => ({
          num_suivi_plt: j?.num_suivi_plt,
          type_plainte: j?.Type_plainte?.lib_type_plt,
          operateur: j?.Operateur?.nom_operateur,
          date_constat: j?.date_constat,
          nom_plt: j?.nom_plt,
          ref_plt: j?.ref_plt,
          statut_plainte: j?.Statut_plainte.libelle,
        }));
      });
    }
  }, [allPlainte]);

  useEffect(() => {
    setTableOp(() =>
      opPlainte
        ?.filter((op) => op.id_type_op === parseInt(lesChamp.id_type_op))
        .map((op) => ({
          value: op.id_operateur,
          label: op.nom_operateur,
        }))
    );
  }, [lesChamp.id_type_op, opPlainte]);

  useEffect(() => {
    setTableQualification(() =>
      typeRequete
        ?.filter((req) => req.id_type_req !== 9)
        .map((req) => ({
          value: req.id_type_req,
          // label: req.id_type_req === 1 ? "Autres" : req.lib_type_req, // Remplacer le libellé si l'ID est 1
          label: req.lib_type_req,
        }))
    );
  }, [lesChamp.id_type_req, typeRequete]);

  useEffect(() => {
    setLesChamp({ ...lesChamp, ["id_operateur"]: selectedOption });
  }, [selectedOption]);

  useEffect(() => {
    setLesChamp({ ...lesChamp, ["id_type_req"]: selectedOptionQualification });
  }, [selectedOptionQualification]);

  useEffect(() => {
    setTableStatuts(() =>
      statustsPlainte
        ?.filter(
          (statut) => statut.id_stat_plt !== 8 && statut.id_stat_plt !== 9
        )
        .map((statut) => ({ value: statut.id_stat_plt, label: statut.libelle }))
    );
  }, [statustsPlainte, lesChamp.id_stat_plt]);

  useEffect(() => {
    const selectedIdsStatuts = selectedOptionStatuts.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, ["id_stat_plt"]: selectedIdsStatuts });
  }, [selectedOptionStatuts]);

  useEffect(() => {
    setTableServices(() =>
      allService?.map((serv) => ({
        value: serv.id_service,
        label: serv.lib_service,
      }))
    );
  }, [allService, lesChamp.id_service]);

  useEffect(() => {
    const selectedIdsServices = selectedOptionServices.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_service: selectedIdsServices });
  }, [selectedOptionServices]);

  useEffect(() => {
    setTableCatPlainte(() =>
      typePlaintes?.map((cat) => ({
        value: cat.id_type_plt,
        label: cat.lib_type_plt,
      }))
    );
  }, [typePlaintes, lesChamp.id_type_plt]);

  useEffect(() => {
    const selectedIdsCatPlainte = selectedOptionCatPlainte.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_type_plt: selectedIdsCatPlainte });
  }, [selectedOptionCatPlainte]);

  useEffect(() => {
    setTableSourceProvenance(() =>
      canauxRecept?.map((provenance) => ({
        value: provenance.id_canal,
        label: provenance.lib_canal,
      }))
    );
  }, [canauxRecept, lesChamp.id_canal]);

  useEffect(() => {
    const selectedIdsSourceProvenance = selectedOptionSourceProvenance.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_canal: selectedIdsSourceProvenance });
  }, [selectedOptionSourceProvenance]);

  useEffect(() => {
    setTableDecision(() =>
      allDecision?.map((dec) => ({
        value: dec.id_dec,
        label: dec.lib_dec,
      }))
    );
  }, [allDecision, lesChamp.id_dec]);

  useEffect(() => {
    const selectedIdsDecision = selectedOptionDecision.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_dec: selectedIdsDecision });
  }, [selectedOptionDecision]);

  useEffect(() => {
    setTableDecision(() =>
      allDecision?.map((dec) => ({
        value: dec.id_dec,
        label: dec.lib_dec,
      }))
    );
  }, [allDecision, lesChamp.id_dec]);

  useEffect(() => {
    const selectedIdsDecision = selectedOptionDecision.map(
      (option) => option.value
    );
    setLesChamp({ ...lesChamp, id_dec: selectedIdsDecision });
  }, [selectedOptionDecision]);

  useEffect(() => {
    setTableSexe(() =>
      lesSexes?.map((sex) => ({
        value: sex.value,
        label: sex.label,
      }))
    );
  }, [lesChamp.lib_sexe]);

  useEffect(() => {
    const selectedIdsSex = selectedOptionSexe.map((option) => option.value);
    setLesChamp({ ...lesChamp, lib_sexe: selectedIdsSex });
  }, [selectedOptionSexe]);

  // const verificate = Object.values(lesChamp).some(
  //   (value) => value.trim() !== ""
  // );

  const verificate = Object.values(lesChamp).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value?.trim() !== "";
  });

  const recupereDonneePlainte = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://private.pigru.arcep.bj/pigru/plainte/get_by_grouped",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            lib_sexe: lesChamp.lib_sexe.length === 0 ? [] : lesChamp.lib_sexe,
            id_stat_plt:
              lesChamp.id_stat_plt.length === 0 ? [] : lesChamp.id_stat_plt,
            id_type_op: lesChamp.id_type_op,
            id_operateur:
              lesChamp.id_operateur.length === 0
                ? []
                : lesChamp.id_operateur.map((item) => item.value),
            id_type_req:
              lesChamp.id_type_req.length === 0
                ? []
                : lesChamp.id_type_req.map((item) => item.value),
            id_type_plt:
              lesChamp.id_type_plt.length === 0 ? [] : lesChamp.id_type_plt,
            id_dec: lesChamp.id_dec.length === 0 ? [] : lesChamp.id_dec,
            id_service:
              lesChamp.id_service.length === 0 ? [] : lesChamp.id_service,
            id_canal: lesChamp.id_canal.length === 0 ? [] : lesChamp.id_canal,
            date_debut: timestamps.date_debut,
            date_fin: timestamps.date_fin,
          },
        }
      );

      setDataPlainte(
        response.data.map((j) => ({
          num_suivi_plt: j?.num_suivi_plt,
          type_plainte: j?.Type_plainte?.lib_type_plt,
          operateur: j?.Operateur?.nom_operateur,
          date_constat: j?.date_constat,
          nom_plt: j?.nom_plt,
          ref_plt: j?.ref_plt,
          statut_plainte: j?.Statut_plainte.libelle,
        }))
      );
    } catch (error) {
      console.log("erreur", error);
    } finally {
      setLoading(false);
    }
  };

  const recupererPlaintesSystem = async () => {
    setLoading(true);
    try {
      if (timestamps?.date_debut > timestamps?.date_fin) {
        Swal.fire({
          title: "Avertissement",
          text: "La date de debut est supérieur à la date de fin",
          icon: "warning",
        });
      } else if (verificate) {
        await recupereDonneePlainte();
      } else {
        Swal.fire({
          title: "Avertissement",
          text: "Veullez selectionner un element",
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est subvenue lors de la recherche",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const user = useSelector(getUserInfo);
  useEffect(() => {
    if (!user?.id) {
      window.location.href = "/";
    }
  }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Plaintes</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistiques des plaintes</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "100px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <label className="fs-sm fw-medium mb-2 mb-xl-1">
                        Nombre de plaintes
                      </label>

                      <h1 className="finance-value">
                        {dataPlainte?.length === 0 ? 0 : dataPlainte?.length}
                      </h1>

                      {/* <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                        Liste totale des plaintes entre deux dates suivant un
                        opérateur
                      </p> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12" className="d-flex flex-column gap-2">
                <Row className="g-2">
                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">
                      Date de début *:
                    </Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de début"
                      value={lesChamp.date_debut}
                      onChange={(e) =>
                        recuperateurGeneral("date_debut", e.target.value)
                      }
                    />
                    {errorMessage.msgDebut && (
                      <span className="text-danger">
                        {errorMessage.msgDebut}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">Date de fin *:</Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de fin"
                      value={lesChamp.date_fin}
                      onChange={(e) =>
                        recuperateurGeneral("date_fin", e.target.value)
                      }
                    />
                    {errorMessage.msgFin && (
                      <span className="text-danger">{errorMessage.msgFin}</span>
                    )}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="statut">Statut :</Form.Label>
                    <Select
                      options={tableStatuts}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionStatuts}
                    />
                    {/* <Form.Select
                      id="statut"
                      value={lesChamp.id_stat_plt}
                      onChange={(e) =>
                        recuperateurGeneral("id_stat_plt", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>

                      {statustsPlainte?.map((statut, key) => {
                        if (
                          statut.id_stat_plt !== 8 &&
                          statut.id_stat_plt !== 9
                        ) {
                          return (
                            <option key={key} value={statut.id_stat_plt}>
                              {statut.libelle}
                            </option>
                          );
                        } else {
                          return null; // Retour par défaut lorsque la condition n'est pas satisfaite
                        }
                      })}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="typeOb">Type d'operateur:</Form.Label>

                    <Form.Select
                      id="typeOp"
                      value={lesChamp.id_type_op}
                      onChange={(e) =>
                        recuperateurGeneral("id_type_op", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {typeOp?.map((typOp, key) => (
                        <option key={key} value={typOp.id_type_op}>
                          {typOp.lib_type_op}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="op">Opérateur :</Form.Label>

                    <Select
                      options={tableOp}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOption}
                      // styles={{
                      //   control: (provided) => ({
                      //     ...provided,
                      //     border: "1px solid #1f365c",
                      //     boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                      //     borderRadius: "5px",
                      //   }),
                      // }}
                    />

                    {/* <Form.Select
                      id="op"
                      value={lesChamp.id_operateur}
                      onChange={(e) =>
                        recuperateurGeneral("id_operateur", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>

                      {opPlainte?.map((op, key) => {
                        if (op.id_type_op === parseInt(lesChamp.id_type_op)) {
                          return (
                            <option key={key} value={op.id_operateur}>
                              {op.nom_operateur}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="service">Service :</Form.Label>
                    <Select
                      options={tableServices}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionServices}
                    />
                    {/* <Form.Select
                      id="service"
                      value={lesChamp.id_service}
                      onChange={(e) =>
                        recuperateurGeneral("id_service", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {allService?.map((serv, key) => (
                        <option key={key} value={serv.id_service}>
                          {serv.lib_service}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="categorie">
                      Catégorie de plainte :
                    </Form.Label>
                    <Select
                      options={tableCatPlainte}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionCatPlainte}
                    />
                    {/* <Form.Select
                      id="categorie"
                      value={lesChamp.id_type_plt}
                      onChange={(e) =>
                        recuperateurGeneral("id_type_plt", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {typePlaintes?.map((typ, key) => (
                        <option key={key} value={typ.id_type_plt}>
                          {typ.lib_type_plt}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>
                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="categorie">
                      Source de provenance :
                    </Form.Label>
                    <Select
                      options={tableSourceProvenance}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionSourceProvenance}
                    />
                    {/* <Form.Select
                      id="categorie"
                      value={lesChamp.id_canal}
                      onChange={(e) =>
                        recuperateurGeneral("id_canal", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {canauxRecept?.map((provenance, key) => (
                        <option key={key} value={provenance.id_canal}>
                          {provenance.lib_canal}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="decision">Décision :</Form.Label>
                    <Select
                      options={tableDecision}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionDecision}
                    />
                    {/* <Form.Select
                      id="decision"
                      value={lesChamp.id_dec}
                      onChange={(e) =>
                        recuperateurGeneral("id_dec", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {allDecision?.map((dec, key) => (
                        <option key={key} value={dec.id_dec}>
                          {dec.lib_dec}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="sex">Sexe :</Form.Label>
                    <Select
                      options={tableSexe}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionSexe}
                    />
                    {/* <Form.Select
                      id="sex"
                      value={lesChamp.lib_sexe}
                      onChange={(e) =>
                        recuperateurGeneral("lib_sexe", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </Form.Select> */}
                  </Col>
                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="fondeOuNon">
                      Qualification :
                    </Form.Label>

                    {/* <Form.Select
                      id="fondeOuNon"
                      value={plainteFondeeOuNon}
                      onChange={(e) => setPlainteFondeeOuNon(e.target.value)}
                    >
                      <option value="">Choisir</option>
                      <option value="plainte fondee">Plainte fondée</option>
                      <option value="plainte non fondee">
                        Plainte non fondée
                      </option>
                    </Form.Select> */}

                    <Select
                      options={tableQualification}
                      isSearchable={true}
                      isMulti
                      onChange={setSelectedOptionQualification}
                      // styles={{
                      //   control: (provided) => ({
                      //     ...provided,
                      //     border: "1px solid #1f365c",
                      //     boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                      //     borderRadius: "5px",
                      //   }),
                      // }}
                    />
                  </Col>

                  <Col className="d-flex flex-row justify-content-center g-2">
                    <Row sm="12" xl="12" className="g-2">
                      <Col>
                        <Button
                          variant="danger"
                          onClick={() => viderEtRafraichi()}
                        >
                          Annuler
                        </Button>
                      </Col>
                      <Col>
                        {" "}
                        <Button
                          variant="success"
                          onClick={() => recupererPlaintesSystem()}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <span>Envoyer</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" xl="12">
                <TablePlainte plaintes={dataPlainte} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
