import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../../layouts/Header.js";
import Footer from "../../../layouts/Footer.js";
import Sidebar from "../../../layouts/Sidebar.js";
import { NavLink } from "react-router-dom";
import TableListePlaintes from "../dataTables/TableListePlaintes.js";
import { useSelector, useDispatch } from "react-redux";
import StepsOfComplain from '../StepsOfComplain.js'
import { getUserInfo, isTokenExpired, verifierAdminAcces, logOut } from "../../../redux/slice/userSlice.js";
import Spinner from "react-bootstrap/Spinner";
import ComplementInformation from '../ComplementInformation.js'
import ResultatDesInvestigations from '../ResultatDesInvestigations.js'
import RapportViewer from '../RapportViewer.js'
import TableDetails from "../TableDetails.js";
import Completude from "../Completude/Completude.js";
import Verdicts from "../Verdicts/Verdicts.js";

import {
    useCreateTypePlainteMutation,
    useGetPlaintesQuery,
    useGetStatutPlaintesQuery,
    useGetTypePlaintesQuery,
    useCreateObsPlainteMutation,
    useUpdatePlainteMutation,
} from "../../../redux/apiSlice/plainteApiSlice.js";
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import Historique from "../Historique/Historique.js";
import Observation from "../Observation/Observation.js";

export default function CompletudeDinfos() {
    const [updatePlainte, { isLoading: loadUpdatePlainte }] =
        useUpdatePlainteMutation();

    const [shouldIDisplay, setshouldIDisplay] = useState(false)
    const [shouldIDisplay2, setshouldIDisplay2] = useState(false)
    const [shouldIDisplay3, setshouldIDisplay3] = useState(false)


    const [resultatDesInvestigations, setresultatDesInvestigations] = useState(false)
    const {
        data: plaintes,
        refetch: refetchPlaintes,
        isLoading: loadPlaintes,
        isSuccess: isSuccessPlaintes,
    } = useGetPlaintesQuery();
    const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
        useGetTypePlaintesQuery();
    const { data: statutPlaintes, isLoading: loadStatutPlainte } =
        useGetStatutPlaintesQuery();
    const { data: operateurs = [], isLoading: loadOperateur } =
        useGetOperateursQuery();
    const [createTypePlainte, { isLoading: loadCreateTypePlainte }] =
        useCreateTypePlainteMutation();

    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mycomplaint, setComplaint] = useState();
    const handleShow = (complaint) => {
        // console.log(complaint);
        setComplaint(complaint);
        setShow(true);
    };

    const [accuses, setAccuse] = useState({ liste: [] });
    const connectVerify = useSelector(getUserInfo);
    const isExpired = useSelector(isTokenExpired);
    const adminAcces = useSelector(verifierAdminAcces);
    const dispash = useDispatch();

    useEffect(() => {
        if (!connectVerify?.id) {
            window.location.href = "/";
          }
        if (isSuccessPlaintes) {
            // Data is available, and you can proceed to use it
            // console.log(plaintes);
            const selectedItems = plaintes.liste.filter(item => item.id_stat_plt === 5 && item.id_type_plt != null && item.id_service != null && item.is_excel == 0)
            // Additional logic or state updates
            setAccuse({ liste: selectedItems })
        }
    }, [isSuccessPlaintes, plaintes]);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const user = useSelector(getUserInfo);

    ///// Skin Switch /////
    const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === "dark") {
            const btnWhite = document.getElementsByClassName("btn-white");

            for (const btn of btnWhite) {
                btn.classList.add("btn-outline-primary");
                btn.classList.remove("btn-white");
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName(
                "btn-outline-primary"
            );

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove("btn-outline-primary");
                btn.classList.add("btn-white");
            }
        }
    };

    useEffect(() => {
        if (!user?.id) {
            window.location.href = "/";
          }
   
        if (localStorage.getItem("token") === "") {
            window.location.href = "/";
        }
        // console.log(retour_plainte);
    }, []);

    useEffect(() => {
        refetchPlaintes();
      }, []);

    useEffect(() => {
        if (isExpired) {
         dispash(logOut())
         window.location.href = "/";
         }  
       }, [isExpired]);

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    if (loadPlaintes) {
        return <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // 100% of the viewport height
            }}
        >
            <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
            />
        </div>;
    }

    if (!isSuccessPlaintes) {
        return <div>Error fetching data</div>;
    }

    return (
        <React.Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <NavLink to="/dashboard">Dashboard</NavLink>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                               Instruction
                            </li>
                        </ol>
                        <h4 className="main-title mb-0">Plainte instruite</h4>
                    </div>
                </div>

                <div>
                    <TableListePlaintes
                        handleclose={handleClose}
                        handleshow={handleShow}
                        plaintes={accuses}
                        adminAcces={adminAcces}

                    />
                </div>
                {/* ----------------Modal de détails----------- */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <TableDetails mycomplaint={mycomplaint}/>
                        <Observation plainte={mycomplaint} />
                        <Completude plainte={mycomplaint} />
                        <Verdicts plainte={mycomplaint} />     
                        <Historique plainte={mycomplaint} />
                    </Modal.Body>
                    <Modal.Footer>
                        <StepsOfComplain Genererrapport={() => { setshouldIDisplay3(true); handleClose() }} setTheshouldIDisplay={() => { setshouldIDisplay(true); handleClose() }} complainStatus={mycomplaint?.id_stat_plt} complainId={mycomplaint?.id_plt} resultatDesInvestigations={() => { setshouldIDisplay2(true); handleClose() }} />
                    </Modal.Footer>
                </Modal>

                {shouldIDisplay && <ComplementInformation display={shouldIDisplay} handleClose={() => { setshouldIDisplay(false) }} id_plt={mycomplaint?.id_plt} />}
                {shouldIDisplay2 && <ResultatDesInvestigations display2={shouldIDisplay2} handleClose2={() => { setshouldIDisplay2(false) }} id_plt={mycomplaint?.id_plt} />}
                {shouldIDisplay3 && <RapportViewer display3={shouldIDisplay3} handleClose3={() => { setshouldIDisplay3(false) }} mycomplaint={mycomplaint} />}
                <Footer />
            </div>
        </React.Fragment>
    );
}
