import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import TablePrestation from "./dataTable/TablePrestation";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import {
  useGetPrestationQuery,
  useCreatePrestationMutation,
  useUpdatePrestationMutation,
  useDeletePrestationMutation,
} from "../../redux/apiSlice/prestationApiSlice";
import { useCreateLogPageMutation } from "../../redux/apiSlice/logPageApiSlice";

import Swal from "sweetalert2";
import { useGetCatPrestationQuery } from "../../redux/apiSlice/categoriePrestationApiSlice";
import { getUserInfo, isTokenExpired, logOut } from "../../redux/slice/userSlice";
import { useSelector, useDispatch } from "react-redux";
export default function Plateforme() {
  const {
    data: prestation,
    refetch: refetchPrestation,
    isLoading: loadPrestation,
  } = useGetPrestationQuery();
  const [createPrestation, { isLoading: loadCreatePrestation }] =
    useCreatePrestationMutation();
  const [updatePrestation, { isLoading: loadUpdatePrestation }] =
    useUpdatePrestationMutation();
  const [deletePrestation, { isLoading: loadDeletePrestation }] =
    useDeletePrestationMutation();
  const { data: categoriePrestation } = useGetCatPrestationQuery();
  const [createLogPage, { isLoading: loadLog }] = useCreateLogPageMutation();
  // modal
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  // console.log("liste prestation", prestation);
  switchSkin(skin);
  const createLog = async () => {
    try {
      await createLogPage({
        content: "Page de creation des prestations",
      }).unwrap();
      // Swal.fire({
      //   title: "Log accomplie!",
      //   text: "Action réussie",
      //   icon: "success",
      // });
    } catch (error) {
      console.log("error", error);
    }
  };
  const connectUser = useSelector(getUserInfo);
  const isExpired = useSelector(isTokenExpired);
  const dispash = useDispatch();
  
  useEffect(() => {
  if (isExpired) {
  dispash(logOut())
  window.location.href = "/";
  }  
  }, [isExpired]);
  
  useEffect(() => {
    createLog();
  }, []);

  useEffect(() => {
    if (!connectUser?.id) {
      window.location.href = "/";
    }
    switchSkin(skin);
  }, [skin]);

  const initPrestation = {
    id_prest: null,
    titre_prest: "",
    desc_prest: "",
    cadre_reg_prest: "",
    demarche_prest: "",
    list_doc_prest: "document",
    duree_prest: "",
    cout_prest: "",
    who_can_prest: "Arcep",
    lien_service_prest: "",
    // piece_prest : [],
    id_cat_prest: null,
  };

  const [creerPrestation, setCreerPrestation] = useState({ ...initPrestation });
  const [idPrestation, setIdPrestation] = useState(0);

  const isRequired =
    creerPrestation.titre_prest === "" ||
    creerPrestation.desc_prest === "" ||
    creerPrestation.cadre_reg_prest === "" ||
    creerPrestation.demarche_prest === "" ||
    creerPrestation.duree_prest === "" ||
    creerPrestation.cout_prest === "" ||
    creerPrestation.lien_service_prest === "" ||
    creerPrestation.id_cat_prest === null;

  const [errorMsg1, setErrorMsg1] = useState({
    titre_prest: "",
    desc_prest: "",
    cadre_reg_prest: "",
    demarche_prest: "",
    duree_prest: "",
    cout_prest: "",
    lien_service_prest: "",
    id_cat_prest: null,
  });

  const informationMessage = {
    titre_prest: "Veuillez saisir un titre",
    desc_prest: "Veuillez saisir une description",
    cadre_reg_prest: "Veuillez saisir un cadre règlementaire",
    demarche_prest: "Veuillez saisir une procédure de soumission",
    duree_prest: "Veuillez saisir une durée",
    cout_prest: "Veuillez saisir un cout",
    lien_service_prest: "Veuillez saisir un lien",
    id_cat_prest: "Veuillez sélectionner une catégorie",
  };

  const afficherMessage = (objetMsg) => {
    Object.entries(objetMsg).forEach(([key, message]) => {
      if (creerPrestation[key] === "" || creerPrestation[key] === "") {
        toast.warning(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const recupererChamp = (field, value) => {
    setCreerPrestation({ ...creerPrestation, [field]: value });

    if (field === "titre_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        titre_prest: "Veuillez saisir un titre",
      }));
    } else if (field === "desc_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        desc_prest: "Veuillez saisir une description",
      }));
    } else if (field === "cadre_reg_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        cadre_reg_prest: "Veuillez saisir un cadre règlementaire",
      }));
    } else if (field === "demarche_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        demarche_prest: "Veuillez saisir une procédure de soumission",
      }));
    } else if (field === "duree_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        duree_prest: "Veuillez saisir une durée",
      }));
    } else if (field === "cout_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        cout_prest: "Veuillez saisir un cout",
      }));
    } else if (field === "lien_service_prest" && value === "") {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        lien_service_prest: "Veuillez saisir un lien",
      }));
    } else if (field === "id_cat_prest" && value === null) {
      setErrorMsg1((prevErrors) => ({
        ...prevErrors,
        id_cat_prest: "Veuillez sélectionner une catégorie",
      }));
    } else {
      setErrorMsg1((prevErrors) => ({ ...prevErrors, [field]: "" }));
    }
  };
  console.log("id cat", errorMsg1.id_cat_prest);
  // const handleFileChange = async (e) => {
  //   const filesImg = e.target.files[0];
  //   recupererChamp("image", filesImg);
  // };
  const viderChamps = () => {
    setCreerPrestation({ ...initPrestation });
  };
  const savePrestation = async () => {
    try {
      if (creerPrestation.id_prest)
        await updatePrestation({
          ...creerPrestation,
          id_prest: creerPrestation.id_prest,
        }).unwrap();
      else {
        await createPrestation(creerPrestation).unwrap();
      }

      Swal.fire({
        title: "Félicitations!",
        text: "Prestation saugardée!",
        icon: "success",
      });

      refetchPrestation();
      setCreerPrestation({ ...initPrestation });
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Utilisez un url valide pour la prestation",
        footer: '<a href="#">Essayer une autre fois?</a>',
      });
    }
  };

  const suppressionPrestation = async () => {
    try {
      let retour_supp = await deletePrestation({
        id_prest: idPrestation,
      }).unwrap();
      if (retour_supp) {
        refetchPrestation();
        handleCloseDelete();
        Swal.fire({
          title: "Félicitations!",
          text: "Suppression réussie!",
          icon: "success",
        });
      }
    } catch (error) {
      // Affichez une notification d'erreur
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erreur lors de la suppression",
        footer: '<a href="#">Essayer une autre fois?</a>',
      });
    }
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Gestion des prestations
              </li>
            </ol>
            <h4 className="main-title mb-0">
              Liste des prestations de l'Arcep
            </h4>
          </div>
        </div>

        <div className="container row">
          <div className="first_container col-sm-6 col-lg-6 ">
            <span className="titre_cat">Liste des prestations</span>

            <hr style={{ width: "100%" }} />

            {loadPrestation ? (
              <p className="w-100 d-flex flex-row justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </p>
            ) : (
              <TablePrestation
                creerPrestation={creerPrestation}
                setCreerPrestation={setCreerPrestation}
                prestation={prestation?.data}
                handleShowDelete={handleShowDelete}
                setIdPrestation={setIdPrestation}
              />
            )}
          </div>
          <div className="second_container col-sm-6 col-lg-6">
            <span className="titre_cat">Nouvelle Prestation</span>

            <hr style={{ width: "100%" }} />

            <div className="row d-flex flex-row">
              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column justify-content-start">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Titre prestation :
                </Form.Label>
                <Form.Control
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Titre de la prestation"
                  value={creerPrestation.titre_prest}
                  onChange={(e) =>
                    recupererChamp("titre_prest", e.target.value)
                  }
                />
                {errorMsg1.titre_prest && (
                  <span className="text-danger">{errorMsg1.titre_prest}</span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Description du service :
                </Form.Label>

                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Description de la prestation"
                  value={creerPrestation.desc_prest}
                  onChange={(e) => recupererChamp("desc_prest", e.target.value)}
                ></textarea>
                {errorMsg1.desc_prest && (
                  <span className="text-danger">{errorMsg1.desc_prest}</span>
                )}
              </div>
              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column justify-content-start">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Categorie de prestation :
                </Form.Label>
                <Form.Select
                  value={creerPrestation.id_cat_prest}
                  onChange={(e) =>
                    recupererChamp("id_cat_prest", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {categoriePrestation?.map((cat, key) => (
                    <option value={cat.id_cat_prest}>
                      {cat.lib_cat_prest}
                    </option>
                  ))}
                </Form.Select>
                {errorMsg1.id_cat_prest === null && (
                  <span className="text-danger">{errorMsg1.id_cat_prest}</span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Cadre réglementaire :
                </Form.Label>

                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Cadre réglementaire"
                  value={creerPrestation.cadre_reg_prest}
                  onChange={(e) =>
                    recupererChamp("cadre_reg_prest", e.target.value)
                  }
                ></textarea>
                {errorMsg1.cadre_reg_prest && (
                  <span className="text-danger">
                    {errorMsg1.cadre_reg_prest}
                  </span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Procédure de soumission :
                </Form.Label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Procédure de soumission"
                  value={creerPrestation.demarche_prest}
                  onChange={(e) =>
                    recupererChamp("demarche_prest", e.target.value)
                  }
                ></textarea>
                {errorMsg1.demarche_prest && (
                  <span className="text-danger">
                    {errorMsg1.demarche_prest}
                  </span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column justify-content-start">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Durée estimée (jours) :
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  id="exampleFormControlInput1"
                  placeholder="Durée de la prestation"
                  value={creerPrestation.duree_prest}
                  onChange={(e) =>
                    recupererChamp("duree_prest", e.target.value)
                  }
                />
                {errorMsg1.duree_prest && (
                  <span className="text-danger">{errorMsg1.duree_prest}</span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Coût du service :
                </Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  id="exampleFormControlInput1"
                  placeholder="Cout de la prestation"
                  value={creerPrestation.cout_prest}
                  onChange={(e) => recupererChamp("cout_prest", e.target.value)}
                />
                {errorMsg1.cout_prest && (
                  <span className="text-danger">{errorMsg1.cout_prest}</span>
                )}
              </div>

              <div className="sous_container_form mb-4 col-xl-6 col-md-12 d-flex flex-column">
                <Form.Label htmlFor="exampleFormControlInput1">
                  Lien du service en ligne :
                </Form.Label>
                <Form.Control
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Url de la plateforme"
                  value={creerPrestation.lien_service_prest}
                  onChange={(e) =>
                    recupererChamp("lien_service_prest", e.target.value)
                  }
                />
                {errorMsg1.lien_service_prest && (
                  <span className="text-danger">
                    {errorMsg1.lien_service_prest}
                  </span>
                )}
              </div>

              {/* <div className="sous_container_form mb-2">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Logo :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="file"
                      id="exampleFormControlInput1"
                      placeholder="fichier"
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>
                </div> */}
              <div className="container_btn d-flex flex-row justify-content-center">
                <Button variant="danger" onClick={() => viderChamps()}>
                  Annuler
                </Button>

                <Button
                  variant="success"
                  onClick={() =>
                    isRequired
                      ? afficherMessage(informationMessage)
                      : savePrestation()
                  }
                  // onClick={() => savePrestation()}
                  // disabled={
                  //   isRequired || loadCreatePrestation || loadUpdatePrestation
                  // }
                  disabled={loadCreatePrestation}
                >
                  {loadCreatePrestation || loadUpdatePrestation ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Enregister</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* ----------------modal de modification---------- */}
        <Modal
          show={showDelete}
          onHide={handleCloseDelete}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4> Etês-vous sûr de vouloir supprimer ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Annuler
            </Button>
            <Button variant="danger" onClick={suppressionPrestation}>
              Valider
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
